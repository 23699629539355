<template>
    <div class="archiveRecord">
        <!-- 存档记录 -->
        <PartitionTitle v-show="historyStatus" class="partitionTitle max" title="存档记录"/>
        <el-scrollbar v-show="historyStatus" ref="scrollbarMax" class="archiveRecord_scorll max_scorll">
            <div class="archiveRecord_content">
                <div v-if="defaultPageStatus" class="content_list">
                    <div v-for="(item,index) in recordData" :key="index" class="contentList_item" @click="archiveEvent(item.game_id)">
                        <!-- 游戏图片 -->
                        <div class="contentList_image">
                            <img :src="item.game_logo">
                        </div>
                        <!-- 记录内容 -->
                        <div class="contentList_content">
                            <p :title="item.game_name">{{ item.game_name}}</p>
                            <p>{{item.archive_time}}</p>
                        </div>
                        <!-- 记录状态 -->
                        <svg data-v-0c6cb13c="" width="10px" height="18px" viewBox="0 0 10 18" version="1.1"><g data-v-0c6cb13c="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-0c6cb13c="" transform="translate(-1440.000000, -340.000000)" fill="#D5A561"><g data-v-0c6cb13c="" transform="translate(1440.000000, 340.000000)"><path data-v-0c6cb13c="" d="M8.77817459,1.29289322 C9.16869888,1.68341751 9.16869888,2.31658249 8.77817459,2.70710678 L2.41421356,9.07106781 L8.77817459,15.4350288 C9.16869888,15.8255531 9.16869888,16.4587181 8.77817459,16.8492424 C8.3876503,17.2397667 7.75448532,17.2397667 7.36396103,16.8492424 L0.292893219,9.77817459 C-0.0976310729,9.3876503 -0.0976310729,8.75448532 0.292893219,8.36396103 L7.36396103,1.29289322 C7.75448532,0.902368927 8.3876503,0.902368927 8.77817459,1.29289322 Z" id="路径备份-2" transform="translate(4.535534, 9.071068) scale(-1, 1) translate(-4.535534, -9.071068) "></path></g></g></g></svg>
                    </div>
                </div>
                <div v-else class="defaultPage">
                    <div class="defaultPage_content">
                        <img src="@/assets/images/user/bg-default-archive.png">
                        <p>您还没有任何存档记录</p>
                    </div>
                </div>
                <div v-if="loading" class="list_loading"></div>
            </div>
        </el-scrollbar>
        <el-scrollbar v-show="historyStatus" ref="scrollbarMin" class="archiveRecord_scorll min_scorll" :style="'height:' + minScorllHeight">
            <div class="archiveRecord_content">
                <div v-if="defaultPageStatus" class="content_list">
                    <div v-for="(item,index) in recordData" :key="index" class="contentList_item" @click="archiveEvent(item.game_id)">
                        <!-- 游戏图片 -->
                        <div class="contentList_image">
                            <img :src="item.game_logo">
                        </div>
                        <!-- 记录内容 -->
                        <div class="contentList_content">
                            <p :title="item.game_name">{{ item.game_name}}</p>
                            <p>{{item.archive_time}}</p>
                        </div>
                        <!-- 记录状态 -->
                        <svg data-v-0c6cb13c="" width="10px" height="18px" viewBox="0 0 10 18" version="1.1"><g data-v-0c6cb13c="" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"><g data-v-0c6cb13c="" transform="translate(-1440.000000, -340.000000)" fill="#D5A561"><g data-v-0c6cb13c="" transform="translate(1440.000000, 340.000000)"><path data-v-0c6cb13c="" d="M8.77817459,1.29289322 C9.16869888,1.68341751 9.16869888,2.31658249 8.77817459,2.70710678 L2.41421356,9.07106781 L8.77817459,15.4350288 C9.16869888,15.8255531 9.16869888,16.4587181 8.77817459,16.8492424 C8.3876503,17.2397667 7.75448532,17.2397667 7.36396103,16.8492424 L0.292893219,9.77817459 C-0.0976310729,9.3876503 -0.0976310729,8.75448532 0.292893219,8.36396103 L7.36396103,1.29289322 C7.75448532,0.902368927 8.3876503,0.902368927 8.77817459,1.29289322 Z" id="路径备份-2" transform="translate(4.535534, 9.071068) scale(-1, 1) translate(-4.535534, -9.071068) "></path></g></g></g></svg>
                    </div>
                </div>
                <div v-else class="defaultPage">
                    <div class="defaultPage_content">
                        <img src="@/assets/images/user/bg-default-archive.png">
                        <p>您还没有任何存档记录</p>
                    </div>
                </div>
                <div v-if="loading" class="list_loading"></div>
            </div>
        </el-scrollbar>
        <div class="blackNav" v-show="!historyStatus">
            <div @click="blackNav">
                <PartitionTitle  class="partitionTitle min" title="返回" black="true"/>
            </div>
        </div>
        <el-scrollbar v-show="!historyStatus" ref="scrollbarMin" class="archiveRecord_scorll history">
            <div class="historyRecord_content">
         
                <ul class="historyList">
                    <li class="childList" v-for="(item, index) in historyData" :key="index"> 
                        <span>{{item.archive_time}}</span>
                        <i :class="['contentList_status', item.archive_status ? 'i-success' : 'i-error']" ></i>
                    </li>
                    <li class="childList_more" v-if="moreStatus == 1"><span @click="reviewMore">查看更多</span></li>
                    <li class="childList_null" v-else-if="moreStatus == 2"> 暂无更多 </li>
                </ul>
            </div>
        </el-scrollbar>
    </div>
</template>
<script>

import PartitionTitle from '@/components/officialWebsite/PartitionTitle';

export default {
    name: "UserCenter",
    props:{
        userMsgStatus:{     //设置点击后加载请求
            type:Boolean,
            default:false
        }
    },
    data() {
        return {
            defaultPageStatus: true,
            minScorllHeight: '100px',
            recordData:[],
            orientation: true,      //:true:竖屏 ,false:横屏 -- 待合并代码使用
            page: 1,                //统计页数
            loading: false,         //加载动画
            historyStatus: true,    //true：列表 false：历史
            historyData:[],         //历史记录数据
            moreStatus: 0,         //查看更多样式
            historyPage:1,
            game_id: 0,              //当前game_id
        };
    },
    mounted(){
        this.scrollMore(this.$refs.scrollbarMax.wrap);
        this.scrollMore(this.$refs.scrollbarMin.wrap);
        // -- 待合并代码使用
        // window.addEventListener("onorientationchange" in window ? 
        //                         "orientationchange" : "resize", 
        //                         this.getOnorienTationChange, 
        //                         false);
    },
    watch:{
        userMsgStatus(state){
            if(!state) return;
            //横屏或竖屏，设置高度-- 待合并代码使用
            // this.getOnorienTationChange();
            // 发送请求获取存档记录
            this.loading = true;
            this.getArchiveData();
        }
    },
    methods: {
        scrollMore(ele){
             ele.onscroll = () => {
                if (ele.scrollTop + ele.clientHeight >= ele.scrollHeight - 1 && !this.loading) {
                    ele.scrollTop = ele.scrollTop - 1;
                    
                    this.loading = true;
                    this.getArchiveData();
                }
            }
        },
        getArchiveData(){
             this.$API.getArchiveData({page: this.page})
                .then((response)=>{
                    let res = response.data;
                    if(res.status){
                        if(res.data.list.length > 0) {
                            this.recordData = [...this.recordData,...res.data.list];

                            // 小屏列表小于，不设置滚动，大于 X 个就设置滚动
                            this.minScorllHeight = res.data.list.length < 8
                            ? (res.data.list.length) * 72 + 'px'
                            : this.minScorllHeight = '585px';

                            // 最后一页销毁滚动监听
                            if(this.page == res.data.meta.last_page){
                                this.$refs.scrollbarMax.wrap.onscroll = null;
                                this.$refs.scrollbarMin.wrap.onscroll = null;
                            }

                            this.page += 1;

                        }else{
                            // 进入无数据后
                            this.minScorllHeight = '350px';
                            this.defaultPageStatus = false;
                        }
                        this.loading = false;
                    }
                });
        },
        getOnorienTationChange (){
            if (window.orientation == 180 || window.orientation == 0) {
                // 竖屏状态
                this.orientation = true;
            }
            
            if (window.orientation == 90 || window.orientation == -90) {
                // 横屏状态
                this.orientation = false;
            }
        },
        blackNav(){
            // 返回导航头
            this.historyStatus = !this.historyStatus
            // 初始化
            this.historyData = [];
            this.historyPage = 1;
            this.moreStatus = 0;
        },
        archiveEvent(game_id){
            this.game_id = game_id;
            this.getHistoryData(game_id);
        },
        reviewMore(){
            this.historyPage++;
            this.getHistoryData(this.game_id);
        },
        getHistoryData(game_id){
                // 历史记录
            this.$API.getArchiveHistory({game_id, page: this.historyPage}).then((response)=>{
                let res = response.data;
                    if(res.status){
                        if(res.data.list.length > 0) {
                            this.historyStatus = false;
                            
                                
                                if(this.historyPagepage != 1){
                                    // 查看更多累数据
                                    this.historyData = [...this.historyData,...res.data.list];
                                }else{
                                    this.historyData = res.data.list;
                                }

                                if(res.data.meta.last_page == this.historyPage && this.historyPage != 1){
                                    // 暂无数据
                                    this.moreStatus = 2;
                                }else if(res.data.meta.last_page != this.historyPage){
                                    // 查看更多
                                    this.moreStatus = 1;
                                }
                            
                            // res.data.meta.last_page == this.historyPage;
                        }else{

                        }
                    }
            });
        }
    },
    components:{
        PartitionTitle,
    }
};
</script>

<style lang="scss" scoped>
    .archiveRecord{
        padding: 0 40px;
        padding-top: 48px;
        .archiveRecord_scorll{
            width: 100%;
            height: 700px;
            .content_list{
                .contentList_item{
                    width: 100%;
                    height: 64px;
                    margin-bottom: 8px;
                    display: flex;
                    flex-wrap: wrap;
                    background-color: white;
                    justify-content: flex-start;
                    .contentList_image{
                        width: 48px;
                        height: 48px;
                        border-radius: 4px;
                        margin: 8px 16px 8px 8px;
                        overflow: hidden;
                        img{
                            width: 100%;
                        }
                    }
                    .contentList_content{
                        color: #606062;
                        P{
                            margin-bottom: 0;
                        }
                        :nth-child(1){
                            margin-top: 6px;
                            height: 20px;
                            font-size: 14px;
                        }
                        :nth-child(2){
                            margin-top: 12px;
                            height: 17px;
                            font-size: 12px;
                        }
                    }
                    >svg{
                        width: 20px;
                        height: 20px;
                        display: inline-block;
                        margin-top: 22.5px;
                        margin-right: 30px;
                        margin-left: auto;
                    }
                }
            }
            .historyRecord_content{
                // 历史导航样式
                .historyList{
                    padding-left: 0;
                    padding-bottom: 20px;
                    .childList{
                        padding: 5px;
                        margin-top: 10px;
                        line-height: 30px;
                        display: flex;
                        background-color: white;
                    }
                 
                    .childList_more{
                        margin-top: 10px;
                        text-align: center;
                        >span{
                            width: 120px;
                            display: inline-block;
                            font-size: 14px;
                            font-weight: bold;
                            // background-color: white;
                        }
                        >span:hover{
                            cursor: pointer;
                            color: #D5A561;
                        }
                    }
                    .childList_null{
                        margin-top: 10px;
                        text-align: center;
                        font-size: 14px;
                        font-weight: bold;
                    }
                    
                    .contentList_status{
                        width: 24px;
                        height: 24px;
                        display: inline-block;
                        margin-top: 4px;
                        margin-right: 24px;
                        color: #606062;
                        margin-left: auto;
                        background-size: cover !important;
                    }
                    .contentList_status.i-success{
                        background: url("../../../assets/images/user/i-yun-success.png") no-repeat left top;
                    }
                    .contentList_status.i-error{
                        background: url("../../../assets/images/user/i-yun-error.png") no-repeat left top;
                    }
                }
            }
            .content_list:hover{
                cursor: pointer;
            }
        }
    }
    .partitionTitle.max{
        margin-bottom: 20px;
    }
    .blackNav{
        margin-bottom: 10px;
    }
    // 缺省图
    .defaultPage{
        width: 100%;
        margin-top: 40px;
        margin-bottom: 16px;
        // background-color: navy;
        .defaultPage_content{
            width: 260px;
            height: 240px;
            margin: 0 auto;
            text-align: center;
            color: #ADADB0;
        }
    }


    .min_scorll{
        display: none;
    }

    @media screen and (max-width: 700px) and (min-width: 500px){
        .archiveRecord{
            padding: 0;
            background-color: white;
        }
        .max_scorll,
        .partitionTitle.max{
            display: none;
        }

        .min_scorll{
            display: block;
        }
        .archiveRecord .archiveRecord_scorll .content_list .contentList_item {
            .contentList_image{
                margin-left: 16px;
            }
        }
        .historyRecord_content{
            background-color: #fffaf4;
            // 历史导航样式
            .historyList{
                padding:0 20px !important;
                padding-bottom: 20px !important;
                .childList{
                    border: 1px solid #FAD8A1;
                }
            }
        }
        .blackNav{
            padding: 10px 20px 10px 20px;
            background-color: #fffaf4;
        }
        .archiveRecord_scorll.history{
            background-color: #fffaf4;
        }
    }
    @media screen and (max-width: 500px) {
        .archiveRecord{
            padding: 0;
            background-color: white;
        }
        .max_scorll,
        .partitionTitle.max{
            display: none;
        }

        .min_scorll{
            display: block;
        }
        .archiveRecord .archiveRecord_scorll .content_list .contentList_item {
            .contentList_image{
                margin-left: 16px;
            }
        }
        .historyRecord_content{
            background-color: #fffaf4;
            // 历史导航样式
            .historyList{
                padding:0 20px !important;
                padding-bottom: 20px !important;
                .childList{
                    border: 1px solid #FAD8A1;
                    .contentList_status{
                        margin-right: 5px !important;
                    }
                }
            }
        }
        .blackNav{
            // margin: 0px 20px;
            padding: 10px 20px 0px 20px;
            background-color: #fffaf4;
        }
        .archiveRecord_scorll.history{
            background-color: #fffaf4;
        }
    }

    //加载动画 
    .list_loading {
        display: flex;
        margin: 0 auto;
        margin-top: 20px;
        border: 2px solid #f3f3f3;
        border-radius: 50%;
        border-top: 2px solid #D5A561;
        width: 20px;
        height: 20px;
        -webkit-animation: spin 2s linear infinite;
        animation: spin 2s linear infinite;
    }
    
    .list_loading.hide{
        display: none;
    }

    @-webkit-keyframes spin {
        0% { -webkit-transform: rotate(0deg); }
        100% { -webkit-transform: rotate(360deg); }
    }

    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }

</style>
